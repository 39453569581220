.h1 {
  @apply font-medium text-4xl;
  line-height: 1.125;
}

.h2 {
  @apply font-medium text-4xl leading-tight;
}
.h3 {
  @apply text-3xl leading-tight;
}
.h4 {
  @apply text-2xl leading-tight;
}
.h5 {
  @apply font-medium text-lg leading-tight;
}
.h6 {
  @apply text-base leading-tight;
}
@screen md {
  .h1 {
    @apply text-5xl;
  }
}

@screen lg {
  .h1 {
    @apply text-6xl;
  }

  .h2 {
    @apply text-5xl;
  }
  .h3 {
    @apply text-4xl;
  }
  .h4 {
    @apply text-3xl;
  }
  .h5 {
    @apply text-2xl;
  }
  .h6 {
    @apply text-xl;
  }
}

:root {
  --color-bg-primary: #2522ba;
  --color-bg-primary-hover: #fff;
  --color-bg-primary-light: #e2e2ff;
  --color-bg-secondary: #6f72b9;
  --color-bg-default: #fff;
  --color-bg-inverse: #5255ac;

  --color-border-primary: #2522ba;
  --color-border-primary-hover: #fff;
  --color-border-primary-light: #e2e2ff;
  --color-border-secondary: #6f72b9;
  --color-border-default: #fff;
  --color-border-inverse: #5255ac;

  --color-text-primary: #2522ba;
  --color-text-primary-light: #928ff5;
  --color-text-primary-dark: #0b0a33;
  --color-text-primary-hover: #2522ba;
  --color-text-secondary: #44467b;
  --color-text-default: #121212;
  --color-text-default-soft: #6a6a6a;
  --color-text-inverse: #fff;
  --color-text-inverse-soft: rgba(255, 255, 255, 0.7);

  --font-display: DM Sans, Trebuchet MS, Arial, sans-serif;
  --font-body: DM Sans, Trebuchet MS, Arial, sans-serif;

  --font-weight-normal: 400;
  --font-weight-display: 600;
  --font-weight-btn: 600;

  --rounded-btn: 1rem;
  --rounded-btn-small: 0.5rem;
  --rounded-btn-tiny: 0.25rem;
  --rounded-multiple-choice: 1rem;
  --rounded-single-choice: 2rem;
  --rounded-text-field: 1rem;
}

.btn {
  @apply py-4 px-12 text-center rounded-btn border border-transparent font-medium transition-all duration-200 cursor-pointer;
}

.btn.small {
  @apply py-3 px-6 rounded-btn-small text-sm;
}

.btn.tiny {
  @apply py-1 px-2 rounded-btn-tiny text-xs;
}

.btn.disabled,
.btn.disabled:hover,
.btn.disabled:focus,
.btn.disabled:active {
  @apply border-gray-400 bg-gray-400 text-white pointer-events-none;
}

.btn.success,
.btn.success:hover,
.btn.success:focus,
.btn.success:active {
  @apply border-green-200 bg-green-200 text-green-900 pointer-events-none;
}

.btn.canceled,
.btn.canceled:hover,
.btn.canceled:focus,
.btn.canceled:active {
  @apply border-red-200 bg-red-200 text-red-900 pointer-events-none;
}

.btn:focus {
  @apply outline-none;
}

.btn-primary {
  @apply bg-primary text-white;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  @apply bg-primary-hover border-primary text-primary-hover;
}

.btn-secondary {
  @apply bg-blue-500 text-white;
}
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  @apply bg-white border-primary text-primary;
}

.btn-outline {
  @apply text-gray-900 border border-gray-400;
}
.btn-outline:hover,
.btn-outline:active,
.btn-outline:focus {
  @apply bg-white border-primary text-primary;
}
.btn-outline-active {
  @apply bg-white border-primary text-primary;
}

.btn-cta {
  @apply bg-blue-500;
}
.btn-text {
  @apply text-primary;
}
.input {
  @apply text-xs;
}
.input::placeholder {
  @apply text-xs;
}

@screen md {
  .input::placeholder {
    @apply text-base;
  }
  .input {
    @apply text-base;
  }
}

.hero {
  @apply py-20;
}

.badge {
  @apply py-1 px-2 rounded-md border;
  width: fit-content;
}

.badge.small {
  padding: 0.125rem 0.5rem;
}
.badge.large {
  @apply py-2 px-4 rounded-lg;
}

.list-item-bullet {
  width: 18px;
  height: 18px;
}

.component-header-icon {
  width: 24px;
  height: 24px;
}

.component-list-item > p {
  white-space: pre-wrap;
}

.title {
  @apply text-5xl text-center;
  color: #333;
  line-height: 1.15;
}

.signature.component .canvas-container {
  width: 650px;
  height: 150px;
}

.textfield-with-button:focus-within {
  @apply border-primary;
}

input:checked + svg {
  display: block;
}
.spinner {
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #f6f;
  border-top-color: #0e0;
  border-right-color: #0dd;
  border-bottom-color: #f90;
}

.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 3s infinite;
    content: "";
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.dialog {
  @apply py-4 px-8 border rounded-3xl border-gray-600
}
